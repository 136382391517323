.readme-container {
  position:absolute;
  background-image: url('../../assets/vscode2.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 520px;
  width: 862px;
  top: 10%;
  left: 20%;
  z-index: 10;
}

.readme-copy {
  height: 430px;
  width: 650px;
  text-align: left;
  color:white;
  margin-left: 97px;
    margin-top: 80px;
  font-size: 10px;
  line-height: 15px;
  font-family: 'Roboto Mono', monospace;
}
