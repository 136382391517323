body {
  /* background-color: white;
  background-image: url('../../assets/desktop.png');
  background-repeat: no-repeat;
  background-size: cover; */
  /* font-family: 'Source Code Pro', monospace; */

}

li {
  display: block;
  width:auto;
  text-align: left;
}


.main--mainframe {
  /* font-family: 'VT323', monospace;
  background-size: cover;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1500px 1fr ;
  grid-template-rows: 1fr auto 1fr 1fr;
  padding-top: 60px;
  /* height: 100vh; */
  background-color: black;
  width: 765px;
  height: fit-content;
  position: relative;
  top: 100px;
  left: 25%;
  border: 1px solid white;
  border-radius: 5px

}

.gbar {
  width: 100%;
}

.img--logo {
  height: 100px;
grid-column: 2 / 3;
justify-self: center;
margin: 0 auto;
grid-row: 1;
margin-top: 20px
}

.span--prompt {
  color: green;
  font-size: 15px;
  font-family: 'VT323', monospace;

}

.ul--commandline {
padding: 1em;
width: 85vw;
max-width: 640px;
min-height: 130px;
font-size:  20px;
/* grid-column: 2 / 3;
grid-row: 2 / 3; */
justify-content: flex-start;
display: flex;
flex-direction: column;
color: white;
font-family: 'VT323', monospace;
margin: 0 auto;
}

.li--phrase {
  justify-content: flex-start;
}

.div--console {
  font-family: 'VT323', monospace;
  display: grid;
  background-color: black;
  height: 100px;
  width:200px;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: auto;
  border: 1px solid green;
  border-radius: 7px;
  box-shadow: 2px 2px green;
  grid-column: 2 / 3;
  margin: 0 auto;
  grid-row: 3;
  margin-bottom: 20px;
}

.label--console {
  color: green;
  font-size: 16px;
  grid-column: 2 / 4;
  grid-row: 2
}

.button--testing {
grid-column: 3 / 4;
grid-row: 3;
font-family: 'VT323', monospace;
background-color:black;
color: green;
border: 1px dotted green;
padding: 7px;
border-radius: 3px;
font-size:  16px;
margin: auto 15px;
grid-column: 2 / 3

}
.button--testing:hover {
cursor:pointer;

}

#secbutton {
  grid-column: 3 / 4
}

.img--desktop {
  position: relative;

}



.img--terminal {
  position: absolute;
  left: 300px;
  top: 300px;
}

/* .div--filename {
  width: auto;
  background-color: lightblue;
  border-radius: 10px;
  margin-left: 10px;
} */

.div--subtext {
  color: white;
  min-height: 65px;
  /* grid-column: 2 / 3;
  grid-row: 1; */
  /* margin-top: 250px; */
  margin: 0px 20px;
  font-size: 20px;
  color: grey;
  font-family: 'Archivo Black', sans-serif;
  font-style: italic;
}
