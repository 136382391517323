.icon__text {
    color: white;
    border-radius: 2px;
    font-family: 'Arimo', sans-serif;
    padding: 1px 0px;
    text-decoration: none;
    word-wrap: break-word;
    display:inline-block;
    text-align:center;
    font-size: 14px;
  }
