.open-window-container {
  position: absolute;
  top: 10%;
  left: 25%;
  justify-self: center;
  height: 425px;
  width: 750px;
  position: absolute;
  background-image: url('../../assets/finder-dark.png');
  background-size: contain;
  z-index: 2;
  display: flex;
}

#mcwindow {
  top: 30%;
  left: 30%;
}

.close-button {
  position: absolute;
  height: 15px;
  width: 15px;
  top: 4px;
  left: 6px;
  filter: opacity(0.5);
  color: red;
}

.close-button:hover {
  cursor: pointer;
}

.window-title {
  position: relative;
  width: fit-content;
  height: fit-content;
  margin: none;
  z-index: 10;
  color: black;
  font-family: 'Arimo', sans-serif;
  top: 3px;
  left: 350px;
  font-size: 14px;
  color: white;
}

#first-fflickr {
  position: relative;
  top: 50px;
  left: 258px;
  list-style: none;
}

#second-fflickr {
  position: relative;
  top: 50px;
  left: 0;
}

#third-fflickr {
  position: relative;
  top: 0;
  left: 0;
}

.default-position {
  position: relative;
  top: 100px;
  left: 100px;
}

.icon-flex {
  display: flex;
  position: absolute;
  top: 100px;
  left: 175px;
  align-items: center;
}
