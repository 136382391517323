.icon__wrapper {
  display: flex;
  flex-direction: column;
  width: 75px;
  margin-bottom: 30px;
  margin-right: 30px;
  /* position: absolute; */
}

.icon__image {
  max-height: 80px;
  width: 55px;
  margin: 0 7px;
}

.icon__folder {
  width: 70px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.icon__image:hover {
  max-height: 80px;
  max-width: 100px;
  cursor: pointer;
}

.icon__wrapper:hover > .icon__text {
  background-color: blue;
}

.icon__extension {
  position: relative;
  top: 65px;
  font-family: 'Arimo', sans-serif;
  font-size: 12px;
  color: #6b7079;
}
