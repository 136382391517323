.desktop-box {
  display: flex;
  justify-content: space-between;
}

.right-flex {
  display: flex;
  flex-direction: column;
  margin-right: 5%;
  margin-top: 5%;
}

.left-flex {
  display: flex;
  width: 210px;
  flex-wrap: wrap;
  margin-left: 5%;
  margin-top: 5%;
}

.taskbar {
  width: 100vw;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../../assets/taskbar.png");
}

.desktop-background {
  height: 100vh;
  width: 100vw;
  background-color: black;
  background-image: url("../../assets/galaxy.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  position: absolute;
}

.mackcali-container {
  position: relative;
  top: 30%;
  left: 30%;
}

#glados-window {
  position: absolute;
  top: -20px;
  left: 100px;
}

#resume-window {
  position: absolute;
  top: 5%;
  left: 20%;
  height: 600px;
  width: 1020px;
  background-image: url("../../assets/resume.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  overflow: hidden;
}

#resume-window .resume-container {
  overflow: scroll;
  position: absolute;
  top: 120px;
  height: 600px;
}

.photo-container {
  position: absolute;
  left: 30%;
  top: 10%;
  width: 25vw;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.photobar {
  max-height: 25px;
  border-radius: 5px 5px 0px 0px;
  overflow-x: hidden;
  object-fit: cover;
  object-position: 0px;
}

.headshot {
  width: 100%;
}
